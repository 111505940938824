<script setup>
import { reactive, provide, onMounted } from 'vue'
import NavBar from "./components/NavBar.vue"
import GroupEvent from "./views/GroupEvent.vue"
import CfFooter from "./components/CfFooter.vue"

const screenState = reactive({
  mode: 'MOBILE'
})
provide('ScreenProvider', screenState)

onMounted(() => {
  if (window.innerWidth < 900) screenState.mode = 'MOBILE'
  else screenState.mode = 'COMPUTER'
})
</script>

<template>
  <nav-bar></nav-bar>
  <GroupEvent></GroupEvent>
  <cf-footer></cf-footer>
</template>

<script setup>
import BannerImg from "@/assets/img/ProductKV2.webp"

const toLine = () => {
  window.location = "https://lin.ee/EssQxJt"
}

function toHref(link) {
  window.location = link
}
</script>

<template>
  <div class="view-group-event">
    <header class="hero" :style="{ backgroundImage: `url(${BannerImg})` }">
      <div class="mask">
        <div class="content">
          <h2>開幕慶</h2>
          <h1>十人團購活動</h1>
          <p>1.1 ~ 3.31</p>
        </div>
      </div>
      <div class="desc">
        <p>本活動期限至 2023/3/31 晚上12:00 截止，單一品項須以10瓶為一組</p>
      </div>
    </header>

    <main>
      <header>
        <h3>團購商品</h3>
      </header>
      <section class="products">
        <div class="product">
          <div class="card">
            <div class="image-wrapper"><img :src="require(`@/assets/img/star_product_3.png`)" /></div>
            <div class="tag">＃保持水嫩雙手肌膚</div>
            <div class="price">十瓶＄5600（原價$8000）</div>
            <p>Silky Hand</p>
            <h2>絲蛋白護手霜</h2>
          </div>
          <div class="content-wrapper">
            <h4>每次不到7元<br/>就能享受德國美容院級的手部保養</h4>
            <p>強化防護 - 羊毛脂能為肌膚形成保護膜，抵禦來自環境的多重傷害</p>
            <p>留住水分 - 植萃精華中的天然油脂能夠預防水分流失，避免粗糙、龜裂</p>
            <p>柔嫩肌膚 - 軟化粗糙肌膚，讓肌膚重拾彈性</p>
            <p>抗老抗皺 - 乳木果油、抗壞血酸棕櫚酸酯等成分能有效延緩皮膚老化</p>

            <button @click="toHref('https://www.chris-farrell.com.tw/product/silky-hand')">產品詳情</button>
          </div>
        </div>
        <div class="product">
          <div class="card">
            <div class="image-wrapper"><img :src="require(`@/assets/img/star_product_1.png`)" /></div>
            <div class="tag">＃敏弱肌膚修護救星</div>
            <div class="price">十瓶$14700（原價＄21000）</div>
            <p>Fibra Gel</p>
            <h2>舒敏復原膠</h2>
          </div>
          <div class="content-wrapper">
            <h4>人體仿生活性成分<br/>一抹見效舒緩各種肌膚不適</h4>
            <span>
              <b>獨家仿生修復成分</b>
              <p>克莉斯瑞特別添加黏多醣，為人體結締組織、玻尿酸的成分，從肌底喚醒你原始肌膚的修復力</p>
            </span>
            <span>
              <b>組織滲透度提升</b>
              <p>肌膚舒緩度+95%，加速養分流動，膚況迅速回穩！<br/>乾癢、敏感、泛紅 一瓶搞定</p>
            </span>

            <button @click="toHref('https://www.chris-farrell.com.tw/product/fibra-gel')">產品詳情</button>
          </div>
        </div>
        <div class="product">
          <div class="card">
            <div class="image-wrapper"><img :src="require(`@/assets/img/star_product_2.png`)" /></div>
            <div class="tag">＃28天還原亮白膚色</div>
            <div class="price">十瓶$15400（原價＄22000）</div>
            <p>Revitam A</p>
            <h2>優白修護霜</h2>
          </div>
          <div class="content-wrapper">
            <h4>全球萬名美容師御用<br />深入肌底 打擊蠟黃 & 膚色不均</h4>
            <span>
              <b>色素沉澱剋星 - 維他命A Vitamin A</b>
              <p>- 改善並預防皮膚皺紋及下垂</p>
              <p>- 減少色素沉著、老年班及曬斑問題</p>
            </span>
            <span>
              <b>高延展性輔助滲透 - 酪梨油 Avocado oil</b>
              <p>- 能夠促進保養品成分滲透、吸收</p>
              <p>- 含有豐富的維他命A、B、D、E、H、K及卵磷脂，能夠延緩肌膚老化</p>
            </span>

            <button @click="toHref('https://www.chris-farrell.com.tw/product/revitam-a')">產品詳情</button>
          </div>
        </div>
      </section>
      <section class="event-step">
        <header>
          <h3>活動三步驟</h3>
        </header>
        <div class="steps">
          <div class="step-card">
            <div class="card">
              <h2>1</h2>
              <img :src="require('@/assets/img/step_1.svg')">
              <h4>召集團購夥伴</h4>
              <p>此次活動產品皆為 10 瓶為單位進行訂購</p>
            </div>
            <div class="card">
              <h2>2</h2>
              <img :src="require('@/assets/img/step_2.svg')">
              <h4>私訊官方 Line@ </h4>
              <p>
                私訊 Chris Farrell 的台灣官方 Line 帳號，並說明你要訂購的品項與數量，請注意購買數量皆須為以10瓶 為一組，並提供收貨地址與收貨人相關資訊後，客服人員會提供付款連結，待完成付款後，會馬上安排由黑貓宅急便出貨
              </p>
            </div>
            <div class="card">
              <h2>3</h2>
              <img :src="require('@/assets/img/step_3.svg')">
              <h4>收貨並確認品項</h4>
              <p>
                在完成付款後，我們將在 3 個工作天內為您安排出貨，待收到貨後請清點品項數量是否正確以及產品包裝是否完整，若有出現品項錯誤或包裝受損的情況，請聯絡官方 Line@，客服人員將會為您處理
              </p>
            </div>
          </div>
        </div>
      </section>

      <button class="to-line" @click="toLine">我要發起團購</button>
    </main>
  </div>
</template>

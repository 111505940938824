<script setup>
import BrandLogoPcImg from '@/assets/img/brand_logo_pc.png'
import BrandLogoWPcImg from '@/assets/img/brand_logo_w_pc.png'
import BrandLogoMImg from '@/assets/img/brand_logo_m.svg'
import { ref, watch, inject, onMounted, onBeforeUnmount } from 'vue';

const ScreenState = inject('ScreenProvider')
const isWhite = ref(false)

const toHome = () => {
  if (ScreenState.mode === 'MOBILE') hasSpread.value = false
  window.location = 'https://www.chris-farrell.com.tw'
}

const hasSpread = ref(false)
const toggleSpread = () => hasSpread.value = !hasSpread.value
const showProductNav = ref(false)
const toggleShowProductNav = boolean => {
  if (ScreenState.mode === "COMPUTER") showProductNav.value = boolean
}
</script>

<template>
  <header
    v-if="ScreenState.mode === 'COMPUTER'"
    id="nav"
    :class="{ white: isWhite, background: showBackground }"
  >
    <div class="nav-container">
      <nav class="pc-nav">
        <ul>
          <li @mouseenter="toggleShowProductNav(true)">
            系列商品
          </li>
          <li>
            <a href="https://www.chris-farrell.com.tw/blog">保養知識</a>
          </li>
          <li>
            <a href="https://www.chris-farrell.com.tw/about">品牌故事</a>
          </li>
        </ul>
      </nav>
      <div class="brand-logo" @click="toHome">
        <img :src="BrandLogoPcImg" alt />
      </div>
      <nav class="pc-nav">
        <ul>
          <li>
            <a href="https://www.chris-farrell.com.tw/login">會員登入</a>
          </li>
          <li>
          </li>
        </ul>
      </nav>
    </div>
    <Transition name="fade-down">
      <div  v-if="showProductNav" class="product-nav" @mouseleave="toggleShowProductNav(false)">
        <ul>
          <li><a href="https://www.chris-farrell.com.tw/product">全系列商品</a></li>
          <p>|</p>
          <li>依膚質選擇：</li>
          <li><a href="https://www.chris-farrell.com.tw/skin-type/sensitive">敏弱肌</a></li>
          <li><a href="https://www.chris-farrell.com.tw/skin-type/dry">乾性肌</a></li>
          <li><a href="https://www.chris-farrell.com.tw/skin-type/combination">混合肌</a></li>
          <li><a href="https://www.chris-farrell.com.tw/skin-type/fragile">特殊美容</a></li>
        </ul>
      </div>
    </Transition>
  </header>

  <header id="nav" :class="{ 'mobile-nav': true, 'spread': hasSpread }" v-else>
    <div class="nav-container">
      <i :class="{ 'nav-icon': true, 'spread': hasSpread }" @click="toggleSpread">
        <i class="top"></i>
        <i class="mid"></i>
        <i class="bot"></i>
      </i>
      
      <div class="brand-logo-mobile" @click="toHome">
        <img :src="BrandLogoMImg" alt />
      </div>
    </div>
    

    <nav :class="{ 'collapse': true, 'spread': hasSpread }" @click="toggleSpread">
      <ul>
        <li class="title">系列產品</li>
        <li><a class="title" href="https://www.chris-farrell.com.tw/product">全部商品</a></li>
        <li class="product-route title">- 依膚質分類 -</li>
        <li class="product-route"><a href="https://www.chris-farrell.com.tw/skin-type/sensitive">敏弱肌</a></li>
        <li class="product-route"><a href="https://www.chris-farrell.com.tw/skin-type/dry">乾肌</a></li>
        <li class="product-route"><a href="https://www.chris-farrell.com.tw/skin-type/combination">混合肌</a></li>
        <li class="product-route"><a href="https://www.chris-farrell.com.tw/skin-type/fragile">特殊美容</a></li>
        <li>
          <a href="https://www.chris-farrell.com.tw/about">品牌故事</a>
        </li>
        <li>
          <a href="https://www.chris-farrell.com.tw/blog">保養知識</a>
        </li>
        <li>
          <a href="https://www.chris-farrell.com.tw/login">會員登入</a>
        </li>
      </ul>
    </nav>
  </header>
</template>
